
  
.box {
    width: 100%;
    

.skeleton-placeholder {
    /* background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);
    background-size: 350px; */
    width: 100%;
    height: 1.45rem;
    border-radius: 3px;
    margin-top: 1.5rem;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cccccc1a;
    font-size: 12px;
    color: #617182;
}
}


  