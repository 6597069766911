.mainLayoutcontainer {
    /* padding-left: 15px !important; */
    overflow: auto;
}

.expendIcon {
display: flex;
width: 23px;
height: 23px;
padding: 5px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 100px;
border: 1px solid var(--color-styles-bg-cards-badges-borders-color-icon-icons-color-neutral, #94A3B8);
background: #FFF;
}

/* .expendIcon svg {
width: 5px;
height: 11px;
flex-shrink: 0;
strokeWidth: 1.5px;
stroke: var(--color-styles-bg-cards-badges-borders-color-icon-icons-color-neutral, #94A3B8);
} */

.inboxHeading {
color: #000;
font-family: Inter;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.css-peqi2l {
    margin-bottom: 8px;
}


.search-filter-wrapper .filterIcons {
    display: flex;
}

.search-filter-wrapper {
    margin-top: 4px;
}

.search-filter-wrapper .filterList {
display: flex;
padding: 0px 10px;
justify-content: space-between;
align-items: center;
border-radius: 5px;
border: 1px solid #DBDDE0;
background-color: #fff;
/* width: 180px; */

}

.search-filter-wrapper .icons {
    height: 18px !important;
    width: 18px !important;
}

.search-filter-wrapper .iconsize {
    height: 15px !important;
    width: 15px !important;
}

.ticket_detail .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticket_detail .ticket_date {
    color: #617182;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticket_detail .description {
    color: #617182;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticket_detail .priority {
    display: flex;
    height: 22px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    /* font-family: Sora; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticket_detail .high {
    background: rgba(227, 56, 67, 0.20);
    color: #E33843;
    padding: 3px 6px;
    border-radius: 5px solid #fff !important ;
    font-size: 10px;
}

.ticket_detail .medium {
    background: rgba(254, 186, 49, 0.20);
    color: #E79B04;
    padding: 3px 6px;
    border-radius: 5px solid #fff !important ;
    font-size: 10px;
}

.ticket_detail .low {
background: rgba(26, 188, 156, 0.20);
color: #1ABC9C;
padding: 3px 6px;
border-radius: 5px solid !important ;
font-size: 10px;
}

.ticket_detail .defaultclass {
    color: whitesmoke;
    background: grey;
    padding: 3px 6px;
    border-radius: 5px solid !important ;
    font-size: 10px;
}

.ticket_detail .ticketinfoicon {
    background: transparent;
}

.ticket_detail .ticketinfoicon:hover {
    background: transparent;
}

.ticketsearchbox {
    border-radius: 5px;
    border: 1px solid #DBDDE0;
    background: #FFF;
}

.sortList  {
    /* background: rgba(222, 222, 222, 0.30) !important; */
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15) !important;
    backdrop-filter: blur(7.5px) !important;
}

.sortingitemlist {
    background-color: #fff;
}

.chakra-ui-dark .sortingitemlist {
    background-color: inherit !important;
    color: #fff !important;
}

.ticketInfopopover {
    width: 100% !important;
   background-color: #fff !important;
   border: 0 !important;
   border: 1px solid #1ABC9C;
   border-radius: 10px;
}
.ticketInfopopover .heading {
    color:  #000  ;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticketInfopopover .text {
    color: #617182;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}



/* ------------------filter screen container--------------- */ 

.filtercontainer {
    border:0;
    box-shadow: none !important;
}

.filtercontainer .heading {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;

}

.filtercontainer .tablist {
    margin: 0 16px;
    border-bottom: 0 !important;
    border-radius: 6px;
    background: var(--color-styles-bg-cards-badges-borders-color-grey-light, #F2F3F4);
}

.filtercontainer .tabcontent {
    display: flex;
}

.filtercontainer .tabcontent .filter_content {
    border-bottom: none !important;
    width: 207px;
    margin: 6px;
    border-radius: 10px;
}

.filtercontainer .tabcontent .filter_text {
    background: #fff;
}

.filtercontainer .filternameheading {
display: flex;
height: 28px;
flex-direction: column;
justify-content: center;
align-self: stretch;
color: #000;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0 !important;
}

.filtercontainer .filtersearchbox {
    display: flex;
padding: 9px 13px;
align-items: center;
gap: 4px;
align-self: stretch;
border-radius: 6px;
border: 1px solid var(--color-styles-text-color-border-color-lt, #D1D5DB) !important;
background: var(--white, #FFF) !important;
box-shadow: none !important;
height: 36px !important;
margin-top: 3px;
}

.dropdownValue div:first-child {
    width: 100% !important;
    border-radius: 6px;
}

.dropdownCondition div:first-child {
    width: 100% !important;
    border-radius: 6px;
}

.dynamic-field-val {
    width: 100% !important;
}

.dynamic-field-val li:first-child {
    width: 100% !important;
    border-radius: 6px;
}

.dynamic-field-val li div:first-child {
    width: 100% !important;
    border-radius: 6px;
}

.filtercontainer .requestedFor {
    margin-right: 0 !important;
}

.filtercontainer .saveBtn {
    display: flex;
    height: 30px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 5px;
    background: var(--color-styles-bg-cards-badges-borders-color-disabled-button-lt, #3182ce);
    color: var(--color-styles-text-color-base-text-color-lt, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.filtercontainer .saveBtn:hover {
    background: var(--color-styles-bg-cards-badges-borders-color-disabled-button-lt, #3182ce);
}

.filtercontainer .clearBtn {
    display: flex;
    width: 87px;
    height: 30px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 5px;
    border: 1px solid var(--color-styles-text-color-border-color-lt, #D1D5DB);
    background: var(--color-styles-surface-foreground-lt, #FFF);
    color: var(--color-styles-text-color-base-text-color-lt, #6B7280);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* ----------------custom popover ticket info --------------------------------- */


.otherticketInfo {
    width: 420px !important;
    height: 60px !important;
     background: rgba(255, 255, 255, 0.60) !important; 
    /* background-color: #000; */
    backdrop-filter: blur(9px) !important;
   border: 0 !important;
}


/* -------------------scroll bar hide and show on hover css---------------------- */

/* #divScroll {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0px;
    }
  }
  #divScroll:hover {
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.2)
    }
  } */


/* Hide the scrollbar by default */
#divScroll {
    overflow: hidden;
    position: relative; /* Required for absolute positioning */
  }
  
  /* Define the scrollbar track */
  #divScroll::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 8px; /* Adjust the width as needed */
    background-color: #f0f0f0; /* Background color of the track */
    opacity: 0; /* Hide the track by default */
    transition: opacity 0.3s; /* Add a smooth transition effect */
  }
  
  /* Show the scrollbar track on hover */
  #divScroll:hover::before {
    opacity: 1;
  }
  
  /* Style the thumb (handle) of the scrollbar */
  #divScroll::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Color of the thumb */
    border-radius: 5px; /* Round the edges of the thumb */
  }
  
  /* Style the thumb (handle) of the scrollbar on hover */
  #divScroll:hover::-webkit-scrollbar-thumb {
    background-color: #0056b3; /* Color of the thumb on hover */
  }
  

  
  .teamsearch input:focus{
    outline: none;
}



/* ----------------Dark mode css-------------------------- */


.chakra-ui-dark .expendIcon{
    background-color: #1d1d1d;
  }
  
.chakra-ui-dark .ticket_detail .title {
    color:inherit
}  
.chakra-ui-dark .ticket_detail .ticket_date{
    color: inherit;
}

.chakra-ui-dark .ticket_detail  .description{
    color: inherit;
}

.chakra-ui-dark .search-filter-wrapper .filterList {
    background-color: inherit;
    border-color: inherit;
}

.chakra-ui-dark .search-filter-wrapper .filterList {
    background-color: inherit;
    border-color: inherit;
}

.chakra-ui-dark  .ticketInfopopover .popover_body {
    border-bottom: 1px solid inherit !important;
    background-color: #2d3748 ;
    border-color: rgba(255, 255, 255, 0.16);
}

.chakra-ui-dark .ticketInfopopover .heading {
    color: #fff ;
   
}

.chakra-ui-dark  .ticketInfopopover .text {
    color: inherit;
} 

.teammenulist {
    background: rgba(222, 222, 222, 0.30) !important;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15) !important;
    backdrop-filter: blur(7.5px) !important;
}

.teammenulist .teamsearch {
    background:#fff;
    border-radius: 10px;
    
}

.teammenulist .teamsearch .inputserach {
    background:#fff;
    border-color:#C4C4C4;
    border-radius: 10px;
}

.chakra-ui-dark .teammenulist {
    background-color: none !important
}
.chakra-ui-dark .teammenulist .teamsearch  {
     background-color: #2d3748 !important;
     border-color: #DBDDE0 ; 
    color: #fff; 
}

.chakra-ui-dark .teamsearch .inputserach {
    background-color: #1D1D1F;
}

.chakra-ui-dark .teammenulist .nodatatext  {
    background-color: #2d3748 !important;
    border-color: #DBDDE0 ; 
   color: #fff; 
}


.chakra-ui-dark .filtercontainer .filternameheading {
    color: #fff;
}

.chakra-ui-dark .filtercontainer .heading {
    color:#fff
}

.chakra-ui-dark .filtercontainer .tablist {
   
    background: #000 !important

}

.chakra-ui-dark .filtercontainer .tabcontent .filter_text {
    background-color: grey !important;
}

.chakra-ui-dark .filtercontainer .filtersearchbox  {
    background-color: inherit !important;
}

.chakra-ui-dark .ticketsearchbox {
    background: inherit;
    border-color: inherit;
    border-radius: 5px;
}

.filteritems > :first-child:is(div) {
    min-width: 0 !important;
}











.switch__toggle {
	font: 1em/1.5 Fredoka;
}


/* Default */
.switch {
	--transDur: 0.3s;
	display: flex;
	align-items: center;
	-webkit-tap-highlight-color: transparent;
}
.switch__fill,
.switch__icon {
	transition: transform var(--transDur) ease-in-out;
}
.switch__fill {
	background-color: var(--bg);
	display: block;
	mix-blend-mode: difference;
	inset: 0;
	height: 100%;
	transform: translateX(-100%);
}
.switch__icon,
.switch__toggle {
	z-index: 1;
}
.switch__icon,
.switch__icon-part {
	position: absolute;
}
.switch__icon {
	display: block;
	top: 0.1em;
	left: 0.1em;
	width: 1.6em;
	height: 1.6em;
    cursor: pointer;
    font-size: 9px;
}
.switch__icon-part {
	border-radius: 50%;
	box-shadow: 0.4em -0.4em 0 0.5em hsl(221.77,83.41%,57.45%) inset;
	top: calc(50% - 0.5em);
	left: calc(50% - 0.5em);
	width: 1em;
	height: 1em;
	transition:
		box-shadow var(--transDur) ease-in-out,
		opacity var(--transDur) ease-in-out,
		transform var(--transDur) ease-in-out;
	transform: scale(0.5);
}
.switch__icon-part ~ .switch__icon-part {
	background-color: hsl(221.77,83.41%,57.45%);
	border-radius: 0.05em;
	box-shadow: none;
	top: 50%;
	left: calc(50% - 0.05em);
	transform: rotate(0deg) translateY(0.5em);
	transform-origin: 50% 0;
	width: 0.1em;
	height: 0.2em;
}
.switch__icon-part:nth-child(3) {
	transform: rotate(45deg) translateY(0.5em);
}
.switch__icon-part:nth-child(4) {
	transform: rotate(90deg) translateY(0.5em);
}
.switch__icon-part:nth-child(5) {
	transform: rotate(135deg) translateY(0.5em);
}
.switch__icon-part:nth-child(6) {
	transform: rotate(180deg) translateY(0.5em);
}
.switch__icon-part:nth-child(7) {
	transform: rotate(225deg) translateY(0.5em);
}
.switch__icon-part:nth-child(8) {
	transform: rotate(270deg) translateY(0.5em);
}
.switch__icon-part:nth-child(9) {
	transform: rotate(315deg) translateY(0.5em);
}
.switch__label,
.switch__toggle,
.switch__toggle-wrap {
	position: relative;
}
.switch__toggle,
.switch__toggle:before {
	display: block;
}
.switch__toggle {
	background-color: hsl(00,0%,85%);
    font-size: 10px;
	border-radius: 25% / 50%;
	box-shadow: 0 0 0 0.125em var(--primaryT);
	padding: 0.1em!important;
	width: 3.5em;
	height: 1.7em;
	-webkit-appearance: none;
	appearance: none;
	transition:
		background-color var(--transDur) ease-in-out,
		box-shadow 0.15s ease-in-out,
		transform var(--transDur) ease-in-out;
}
.switch__toggle:before {
	background-color: hsl(0,0%,100%);
	border-radius: 50%;
	content: "";
	width: 1.5em;
	height: 1.5em;
	transition:
		background-color var(--transDur) ease-in-out,
		transform var(--transDur) ease-in-out;
}
.switch__toggle:focus {
	box-shadow: 0 0 0 0.125em var(--primary);
	outline: transparent;
}

/* Checked */
.switch__toggle:checked {
	background-color: hsl(0,0%,23%);
}
.switch__toggle:checked:before,
.switch__toggle:checked ~ .switch__icon {
	transform: translateX(1.7em);
}
.switch__toggle:checked:before {
	background-color: hsl(0,0%,100%);
    box-shadow: 1px 1px 1px #ccc;
}
.switch__toggle:checked ~ .switch__fill {
	transform: translateX(0);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(1) {
	box-shadow: 0.2em -0.2em 0 0.2em hsl(10,0%,10%) inset;
	transform: scale(1);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part ~ .switch__icon-part {
	opacity: 0;
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(2) {
	transform: rotate(45deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(3) {
	transform: rotate(90deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(4) {
	transform: rotate(135deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(5) {
	transform: rotate(180deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(6) {
	transform: rotate(225deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(7) {
	transform: rotate(270deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(8) {
	transform: rotate(315deg) translateY(0.8em);
}
.switch__toggle:checked ~ .switch__icon .switch__icon-part:nth-child(9) {
	transform: rotate(360deg) translateY(0.8em);
}
.switch__icon.dark{
    left: 0.4em;
}
/* .switch__toggle-wrap {
	margin: 0 0.75em;
} */

/* @supports selector(:focus-visible) {
	.switch__toggle:focus {
		box-shadow: 0 0 0 0.125em var(--primaryT);
	}
	.switch__toggle:focus-visible {
		box-shadow: 0 0 0 0.125em var(--primary);
	}
} */

.header-logo{
    width:100%;transition: width 300ms; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    margin-left: 8px;
}
.header-inner.collapse{width:14%;    margin-left:0px;
}

.header-inner .header-logo.collapse img{display: none;}
.header-inner.collapse {
    width: 62px;
    margin-left: 0px;
    /* transition: all .2s; */
}
.header-logo.collapse {
    margin-left: 0;
}
.header-inner.collapse .header-logo.collapse img {
    display: block;
}
.header-inner{width: 240px; border-right: 1px solid #eaeaea;}
.sidebar-flex-toggle{
    font-family: 'Inter', sans-serif!important;
    height: 90vh;
    width: 240px;
    transition: width 300ms;
    font-weight: 500;
}
.sidebar-nested-parent-child .sidebar-nested-child{font-weight: 400;}
.sidebar-flex-toggle.collapse{
 width: 59px;
}

.sticky-arrow {
    position: fixed;
    transition: width 300ms;
    top: 50px;
    width: 50px; 
    height: 50px; 
    z-index: 100; 
  
}
.sticky-arrow.collapse{height: 5px;}
.sidebar-title{ color : #6c7280!important}
.sidebar-icon{color : #94a3b8!important}
.header,.footer{
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    color:#6c7280!important;

}
.sidebar-nested-parent-child{margin-left: 15px; padding-left: 10px!important;}

a.chakra-link:hover svg,
.chakra-link:hover svg,
.sidebar-nested-parent-child:hover svg:first-child,
.sidebar-nested-parent:hover svg:first-child  {
    color: #1E90FF !important;
    transition: all .4s;
}

a.chakra-link.sidebar-nested-child {
    display: block;
}
.header.collapse .header-section {
    width: 100%;
}
.header-section{
    width: calc(100% - 240px);
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}
a.chakra-link.live-chat svg, a.chakra-link.admin-app svg {
    width: 22px;
}
a.chakra-link.live-chat p, a.chakra-link.admin-app p, a.chakra-link.virtual-agent p, a.chakra-link.learning p {
    padding-left: 6px!important;
}
.custom-datepicker{
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: inherit;
    -webkit-padding-start: var(--chakra-space-3);
    padding-inline-start: var(--chakra-space-3);
    -webkit-padding-end: var(--chakra-space-3);
    padding-inline-end: var(--chakra-space-3);
    height: var(--chakra-sizes-8);
    border-radius: 5px;
    border: 1px solid;
    border-color: #D1D5DB;
    background: inherit;
}

.monitor-chats svg{width: 18px; height: 18px; margin-right: 4px;}
/* svg.chakra-icon.sidebar-icon {
    margin-right: 4px;
} */
.sidebar-nested-parent-child p.chakra-text {
    padding-left: 0px!important;
}
.sidebar-nested-parent-child svg {
    margin-right: 2px!important;
}
.sidebar-nested-parent svg.chakra-icon.sidebar-icon {
    margin-right: 4px;
}
.sidebar-nested-parent-child {
    margin-bottom: 0px!important;
}

	/* css to show scrollbar */
    ::-webkit-scrollbar {
        width: 6px; /* Adjust the width as needed */
      }
      
      /* Track color */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
    
    /* Thumb color */  
      ::-webkit-scrollbar-thumb {
        background: #888; 
      }

      a.chakra-link.sidebar-nested-child {
        padding-left: 18px;
    }